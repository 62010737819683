export interface AppAdministratorOrganizationsDetailMembersFormCreate {
  organization_id: string | string[];
  email: string;
  password: string;
  role: string;
  is_selected: boolean;
  content: {
    name: string;
    surname: string;
  };
  is_verified: boolean;
}

export class AppAdministratorOrganizationsDetailMembersFormCreate implements AppAdministratorOrganizationsDetailMembersFormCreate {
  organization_id: string | string[];
  email: string;
  password: string;
  role: string;
  is_selected: boolean;
  content: {
    name: string;
    surname: string;
  };
  is_verified: boolean;

  constructor(organization_id?: string | string[]) {
    this.organization_id = organization_id ?? "";
    this.email = "";
    this.password = "";
    this.role = "";
    this.is_selected = false;
    this.content = {
      name: "",
      surname: "",
    };
    this.is_verified = false;
  }
}