
// Vue
import {Options, Vue} from "vue-class-component";

// Store
import store from "@/store/index";

// Models
import {AppAdministratorOrganizationsDetailMembersIndex} from "@/models/app/administrator/organizations/detail/members/index";
import {ResponseIndex} from "@/models/response";

// Components
import {
  ElRow,
  ElCol,
} from "element-plus";
import AppAdministratorOrganizationsDetailMembersFormCreateIndexVue from "@/views/app/administrator/organizations/detail/members/form/create.vue";
import AppAdministratorOrganizationsDetailMembersFormUpdateIndexVue from "@/views/app/administrator/organizations/detail/members/form/update.vue";
import AppAdministratorOrganizationsDetailMembersFormRemoveIndexVue from "@/views/app/administrator/organizations/detail/members/form/remove.vue";

// Services
import {getRequest} from "@/services/api/request";

@Options({
  components: {
    ElRow,
    ElCol,
    AppAdministratorOrganizationsDetailMembersFormCreateIndexVue,
    AppAdministratorOrganizationsDetailMembersFormUpdateIndexVue,
    AppAdministratorOrganizationsDetailMembersFormRemoveIndexVue,
  },
})
export default class AppAdministratorOrganizationsDetailMembersIndexVue extends Vue {
  isLoading: boolean | null = false;

  organizationMembers: Array<AppAdministratorOrganizationsDetailMembersIndex> = [];

  isAdministrator(userId: string): boolean {
    return userId === store.getters.getAuthentication.id;
  }

  async getOrganizationMembers(): Promise<void> {
    this.isLoading = true;
    await getRequest({
      uri: `/administration/organization-members`,
      formData: {
        organization_id: this.$route.params.id,
      },
      isProtected: true,
    }).then((r: ResponseIndex) => {
      if (r.data) {
        this.organizationMembers = r.data.sort(function (a, b) {
          if (a.user.content.name < b.user.content.name) {
            return -1;
          }
          if (a.user.content.name > b.user.content.name) {
            return 1;
          }
          return 0;
        });
        this.organizationMembers = this.organizationMembers.sort(function (a, b) {
          if (a.role < b.role) {
            return -1;
          }
          if (a.role > b.role) {
            return 1;
          }
          return 0;
        });
      }
    });
    this.isLoading = false;
  }

  async created(): Promise<void> {
    await this.getOrganizationMembers();
  }
}
