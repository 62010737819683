export interface AppAdministratorOrganizationsDetailMembersFormUpdate {
  id: string;
  user_id: string;
  organization_id: string;
  role: string;
  is_selected: boolean;
  user: {
    id: string;
    email: string;
    content: {
      name: string;
      surname: string;
    };
    is_verified: boolean;
  };
}

export class AppAdministratorOrganizationsDetailMembersFormUpdate implements AppAdministratorOrganizationsDetailMembersFormUpdate {
  id: string;
  user_id: string;
  organization_id: string;
  role: string;
  is_selected: boolean;
  user: {
    id: string;
    email: string;
    content: {
      name: string;
      surname: string;
    };
    is_verified: boolean;
  };

  constructor() {
    this.id = "";
    this.user_id = "";
    this.organization_id = "";
    this.role = "";
    this.is_selected = false;
    this.user = {
      id: "",
      email: "",
      content: {
        name: "",
        surname: "",
      },
      is_verified: false,
    };
  }
}