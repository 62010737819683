
// Vue
import {Options, Vue} from "vue-class-component";

// Models
import {AppAdministratorOrganizationsDetailMembersFormUpdate} from "@/models/app/administrator/organizations/detail/members/form/update";
import {ResponseIndex} from "@/models/response";

// Components
import {
  ElDialog,
  ElForm,
  ElFormItem,
  ElInput,
  ElSelect,
  ElOption,
  ElButton,
  ElAlert,
} from "element-plus";
import {Edit} from "@element-plus/icons-vue";

// Services
import {postRequest} from "@/services/api/request";

@Options({
  props: [
    "organizationMember",
  ],
  components: {
    ElDialog,
    ElForm,
    ElFormItem,
    ElInput,
    ElSelect,
    ElOption,
    ElButton,
    ElAlert,
    Edit,
  },
})
export default class AdministratorOrganizationsDetailMembersFormUpdateIndexVue extends Vue {
  isLoading: boolean | null = false;
  isDialogVisible: boolean | null = false;

  organizationMember: AppAdministratorOrganizationsDetailMembersFormUpdate = new AppAdministratorOrganizationsDetailMembersFormUpdate();

  formData: AppAdministratorOrganizationsDetailMembersFormUpdate = new AppAdministratorOrganizationsDetailMembersFormUpdate();
  formDataRules = {
    user: {
      content: {
        name: [
          {
            required: true,
            message: "Polje nije popunjeno",
            trigger: "blur",
          },
        ],
        surname: [
          {
            required: true,
            message: "Polje nije popunjeno",
            trigger: "blur",
          },
        ],
      },
    },
  };

  $refs!: {
    formComponent: HTMLFormElement;
  };

  validateForm(): void {
    this.$refs.formComponent.validate((response: any) => {
      if (response) {
        this.submitForm();
      }
    });
  }

  async submitForm(): Promise<void> {
    this.isLoading = true;
    await postRequest({
      uri: "/administration/organization-member/update",
      formData: this.formData,
      isProtected: true,
      isSuccessNotificationVisible: true,
      isErrorNotificationVisible: true,
    }).then((r: ResponseIndex) => {
      if (r.status === "success") {
        this.$emit('getOrganizations');
        this.isDialogVisible = false;
        this.resetFormData();
      }
    });
    this.isLoading = false;
  }

  setFormData(): void {
    this.formData = this.organizationMember;
  }

  resetFormData(): void {
    this.formData = new AppAdministratorOrganizationsDetailMembersFormUpdate();
  }

  updated(): void {
    this.setFormData();
  }
}
