
// Vue
import {Options, Vue} from "vue-class-component";

// Components
import {
  ElRow,
  ElCol,
} from "element-plus";
import AdministratorNavigationVue from "@/components/administrator/navigation.vue";
import AppAdministratorOrganizationsDetailInformationIndexVue from "@/views/app/administrator/organizations/detail/information/index.vue";
import AppAdministratorOrganizationsDetailMembersIndexVue from "@/views/app/administrator/organizations/detail/members/index.vue";

@Options({
  components: {
    ElRow,
    ElCol,
    AdministratorNavigationVue,
    AppAdministratorOrganizationsDetailInformationIndexVue,
    AppAdministratorOrganizationsDetailMembersIndexVue,
  },
})
export default class AppAdministratorOrganizationsDetailIndexVue extends Vue {}
