export interface AppAdministratorOrganizationsDetailInformationIndex {
  id: string;
  user_id: string;
  content: {
    name: string;
    address: string;
    city: string;
    country: string;
  };
}

export class AppAdministratorOrganizationsDetailInformationIndex implements AppAdministratorOrganizationsDetailInformationIndex {
  id: string;
  user_id: string;
  content: {
    name: string;
    address: string;
    city: string;
    country: string;
  };

  constructor() {
    this.id = "";
    this.user_id = "";
    this.content = {
      name: "",
      address: "",
      city: "",
      country: "",
    };
  }
}