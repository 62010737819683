
// Vue
import {Options, Vue} from "vue-class-component";

// Models
import {AppAdministratorOrganizationsDetailInformationIndex} from "@/models/app/administrator/organizations/detail/information/index";
import {ResponseIndex} from "@/models/response";

// Components
import {
  ElRow,
  ElCol,
} from "element-plus";
import AppAdministratorOrganizationsDetailInformationFormUpdateIndexVue from "@/views/app/administrator/organizations/detail/information/form/update.vue";

// Services
import {getRequest} from "@/services/api/request";

@Options({
  components: {
    ElRow,
    ElCol,
    AppAdministratorOrganizationsDetailInformationFormUpdateIndexVue,
  },
})
export default class AppAdministratorOrganizationsDetailInformationIndexVue extends Vue {
  isLoading: boolean | null = false;

  organizationInformation: AppAdministratorOrganizationsDetailInformationIndex = new AppAdministratorOrganizationsDetailInformationIndex();

  async getOrganizationInformation(): Promise<void> {
    this.isLoading = true;
    await getRequest({
      uri: `/administration/organization/information`,
      formData: {
        id: this.$route.params.id
      },
      isProtected: true,
    }).then((r: ResponseIndex) => {
      if (r.data) {
        this.organizationInformation = r.data;
      }
    });
    this.isLoading = false;
  }

  async created(): Promise<void> {
    await this.getOrganizationInformation();
  }
}
