import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, withModifiers as _withModifiers, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-59140a1e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "administrator-organizations-detail-members-create" }
const _hoisted_2 = { class: "action" }
const _hoisted_3 = { class: "form" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Plus = _resolveComponent("Plus")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_alert = _resolveComponent("el-alert")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_el_button, {
        type: "primary",
        class: "el-button--grey el-button--square",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isDialogVisible = true))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Plus)
        ]),
        _: 1
      })
    ]),
    _createVNode(_component_el_dialog, {
      title: "Dodaj",
      modelValue: _ctx.isDialogVisible,
      "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.isDialogVisible) = $event))
    }, {
      default: _withCtx(() => [
        (_ctx.isUserForbidden)
          ? (_openBlock(), _createBlock(_component_el_alert, {
              key: 0,
              title: "Korisnik je već u organizaciji.",
              type: "error",
              closable: false
            }))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_el_form, {
            model: _ctx.formData,
            rules: _ctx.formDataRules,
            ref: "formComponent",
            onSubmit: _withModifiers(_ctx.validateForm, ["prevent"])
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_form_item, {
                label: "E-mail",
                prop: "email"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input, {
                    type: "email",
                    placeholder: "npr. ivan.horvat@mail.com",
                    modelValue: _ctx.formData.email,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formData.email) = $event)),
                    onChange: _ctx.searchForMember
                  }, null, 8, ["modelValue", "onChange"])
                ]),
                _: 1
              }),
              _createVNode(_component_el_form_item, {
                label: "Lozinka",
                prop: "password",
                class: _normalizeClass({ 'd-none': _ctx.isUserFound || _ctx.isUserForbidden })
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input, {
                    type: "password",
                    placeholder: "········",
                    "show-password": "",
                    modelValue: _ctx.formData.password,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formData.password) = $event))
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }, 8, ["class"]),
              _createVNode(_component_el_form_item, {
                label: "Uloga",
                prop: "role",
                class: _normalizeClass({ 'd-none': _ctx.isUserForbidden })
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_select, {
                    modelValue: _ctx.formData.role,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formData.role) = $event)),
                    placeholder: "Odaberite"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_option, {
                        label: "Administrator",
                        value: "administrator"
                      }),
                      _createVNode(_component_el_option, {
                        label: "Trener",
                        value: "coach"
                      }),
                      _createVNode(_component_el_option, {
                        label: "Kondicijski trener",
                        value: "fitness-coach"
                      }),
                      _createVNode(_component_el_option, {
                        label: "Manager",
                        value: "manager"
                      }),
                      _createVNode(_component_el_option, {
                        label: "Mentor",
                        value: "mentor"
                      }),
                      _createVNode(_component_el_option, {
                        label: "Igrač",
                        value: "player"
                      })
                    ]),
                    _: 1
                  }, 8, ["modelValue"])
                ]),
                _: 1
              }, 8, ["class"]),
              _createVNode(_component_el_form_item, {
                label: "Ime",
                prop: "content.name",
                class: _normalizeClass({ 'd-none': _ctx.isUserFound || _ctx.isUserForbidden })
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input, {
                    placeholder: "npr. Tomislav",
                    modelValue: _ctx.formData.content.name,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.formData.content.name) = $event))
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }, 8, ["class"]),
              _createVNode(_component_el_form_item, {
                label: "Prezime",
                prop: "content.surname",
                class: _normalizeClass({ 'd-none': _ctx.isUserFound || _ctx.isUserForbidden })
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input, {
                    placeholder: "npr. Šarić",
                    modelValue: _ctx.formData.content.surname,
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.formData.content.surname) = $event))
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }, 8, ["class"]),
              _createVNode(_component_el_form_item, {
                label: "Verifikacija",
                prop: "is_verified",
                class: _normalizeClass({ 'd-none': _ctx.isUserFound || _ctx.isUserForbidden })
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_select, {
                    modelValue: _ctx.formData.is_verified,
                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.formData.is_verified) = $event)),
                    placeholder: "Odaberite"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_option, {
                        label: "Da",
                        value: true
                      }),
                      _createVNode(_component_el_option, {
                        label: "Ne",
                        value: false
                      })
                    ]),
                    _: 1
                  }, 8, ["modelValue"])
                ]),
                _: 1
              }, 8, ["class"]),
              _createVNode(_component_el_form_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_el_button, {
                    type: "primary",
                    class: "el-button--primary el-button--block",
                    "native-type": "submit",
                    disabled: _ctx.isUserForbidden,
                    loading: _ctx.isLoading,
                    onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.validateForm()))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Spremi ")
                    ]),
                    _: 1
                  }, 8, ["disabled", "loading"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["model", "rules", "onSubmit"])
        ])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}