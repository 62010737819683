
// Vue
import {Options, Vue} from "vue-class-component";

// Models
import {AppAdministratorOrganizationsDetailMembersIndex} from "@/models/app/administrator/organizations/detail/members/index";
import {AppAdministratorOrganizationsDetailMembersFormCreate} from "@/models/app/administrator/organizations/detail/members/form/create";
import {ResponseIndex} from "@/models/response";

// Components
import {
  ElDialog,
  ElForm,
  ElFormItem,
  ElInput,
  ElSelect,
  ElOption,
  ElButton,
  ElAlert,
} from "element-plus";
import {Plus} from "@element-plus/icons-vue";

// Services
import {getRequest, postRequest} from "@/services/api/request";

@Options({
  props: [
    "organizationMembers",
  ],
  components: {
    ElDialog,
    ElForm,
    ElFormItem,
    ElInput,
    ElSelect,
    ElOption,
    ElButton,
    ElAlert,
    Plus,
  },
})
export default class AdministratorOrganizationsDetailMembersFormCreateIndexVue extends Vue {
  isLoading: boolean | null = false;
  isDialogVisible: boolean | null = false;
  isUserFound: boolean | null = false;
  isUserForbidden: boolean | null = false;

  formData: AppAdministratorOrganizationsDetailMembersFormCreate = new AppAdministratorOrganizationsDetailMembersFormCreate();
  formDataRules = {
    email: [
      {
        required: true,
        type: "email",
        message: "Polje nije popunjeno",
        trigger: "blur",
      },
    ],
    password: [
      {
        required: true,
        message: "Polje nije popunjeno",
        trigger: "blur",
      },
    ],
    content: {
      name: [
        {
          required: true,
          message: "Polje nije popunjeno",
          trigger: "blur",
        },
      ],
      surname: [
        {
          required: true,
          message: "Polje nije popunjeno",
          trigger: "blur",
        },
      ],
    },
  };

  organizationMembers!: Array<AppAdministratorOrganizationsDetailMembersIndex>;

  $refs!: {
    formComponent: HTMLFormElement;
  };

  validateForm(): void {
    if (this.isUserFound) {
      this.submitForm();
    } else {
      this.$refs.formComponent.validate((response: any) => {
        if (response) {
          this.submitForm();
        }
      });
    }
  }

  async submitForm(): Promise<void> {
    this.isLoading = true;
    await postRequest({
      uri: "/administration/organization-member/create",
      formData: this.formData,
      isProtected: true,
      isSuccessNotificationVisible: true,
      isErrorNotificationVisible: true,
    }).then((r: ResponseIndex) => {
      if (r.status === "success") {
        this.$emit('getOrganizationMembers');
        this.isDialogVisible = false;
        this.resetFormData();
      }
    });
    this.isLoading = false;
  }

  async searchForMember(): Promise<void> {
    this.isLoading = true;
    await getRequest({
      uri: `/administration/organization-member/search`,
      formData: {
        email: this.formData.email
      },
      isProtected: true,
    }).then((r: ResponseIndex) => {
      if (r.data) {
        const users = this.organizationMembers.filter((item: any) => {
          return item.user.id === r.data.id
        });

        if (users.length) {
          this.isUserFound = false;
          this.isUserForbidden = true;
        } else {
          this.isUserFound = false;
          this.isUserForbidden = false;
        }
      }

      if (r.status === "warning") {
        this.isUserFound = false;
        this.isUserForbidden = false;
      }
    });
    this.isLoading = false;
  }

  created(): void {
    this.formData.organization_id = this.$route.params.id;
  }

  resetFormData(): void {
    this.formData = new AppAdministratorOrganizationsDetailMembersFormCreate(this.$route.params.id);
  }
}
